/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { uploadToSignedUrl, getUploadSignedUrl, getDownloadSignedUrl } from "helpers/s3";
import FilesActions from "redux-react/actions/filesActions";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Fade, Grid, Icon, IconButton, InputAdornment } from "@mui/material";
import { read, utils } from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import DragAndDrop from "pages/sources/components/DragAndDrop";

const AVAILABLES_CHANNELS = [
	{
		type: "SHFY",
		importMethod: "shopifyPage"
	}
];

export default function Step1Upload({ validStep, handleNext, handleSave }) {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const [loadingProgress, setLoadingProgress] = useState(false);

	const [url, setUrl] = useState("");
	const [urlError, setUrlError] = useState("");
	const [loadUrl, setLoadUrl] = useState(false);

	const [config, setConfig] = useState({});

	const [possibleIntegrations, setPossibleIntegrations] = useState([]);

	const selectMethod = (method, files = []) => {
		handleSave({
			importMethod: method,
			files: files
		});
	};

	function loadConfig() {
		const onSuccess = res => {
			if (!lod_.isNil(res?.config)) {
				setConfig(res.config);

				if (res.config.channelAssistantID) {
					dispatch(
						SourceActions.getIntegrationChannels(
							{
								assistantID: res.config.channelAssistantID,
								channels: AVAILABLES_CHANNELS.map(channel => channel.type)
							},
							res => {
								if (!lod_.isNil(res)) {
									setPossibleIntegrations(res);
								}
							}
						)
					);
				}
			}
		};
		dispatch(FaiqActions.getFaiqConfig(profile.assistantID, onSuccess));
	}

	/**
	 * Upload file to S3
	 */
	const uploadFileToS3 = async (file, path) => {
		setLoadingProgress(true);
		// Get upload URL
		let uploadURL = await getUploadSignedUrl(file, path);
		// Upload to S3
		await uploadToSignedUrl(uploadURL, file);
		// Get download URL
		let downloadURLResult = await getDownloadSignedUrl(file, path);

		let accessURL = downloadURLResult?.accessURL;

		if (accessURL && file) {
			return { success: true, path: path, url: accessURL };
		} else {
			return { success: false };
		}
	};
	/**
	 * Handle CSV file
	 */
	const handleCSVFile = async file => {
		const reader = new FileReader();
		return new Promise((resolve, reject) => {
			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				resolve(data);
			};
			reader.onerror = reject;
			reader.readAsArrayBuffer(file);
		});
	};

	/**
	 * Upload file to S3
	 * @param {*} file - File to upload
	 * @returns {Promise<void>} - Promise
	 */
	const uploadFile = async file => {
		const uuid = uuidv4();
		const path = `source/${uuid}`;
		const mimeType = file.type;
		const tabWords = ["sheet", "csv", "excel"];
		const isExcel = tabWords.some(word => mimeType.includes(word));

		// Source metadatas
		const metadatas = {};
		// Default type is file
		let type = "file";
		// Values used for csv
		let values = [];

		if (isExcel) {
			// Case for excel files
			type = "csv";
			values = await handleCSVFile(file);
		}

		// Get cleaned file name => Remove extension
		if (!lod_.isNil(file.name)) {
			let cleanedFileName = file.name.split(".")[0];
			// Set the file name in the metadatas (title and description)
			if (cleanedFileName) {
				metadatas.title = cleanedFileName;
				metadatas.description = cleanedFileName;
			}
		}

		// Upload file to S3
		let uploadResult = await uploadFileToS3(file, path);

		if (!uploadResult.success) {
			setLoadingProgress(false);
			return;
		}

		// File has been uploaded, we can valid the step
		validStep();

		file.path = uploadResult.path;

		// Save the file
		handleSave({
			type: type,
			file: file,
			url: uploadResult.url,
			values: values,
			metadatas: metadatas
		});
		setLoadingProgress(false);
	};

	/**
	 * Handle upload file
	 */
	const handleUploadFile = async e => {
		setLoadingProgress(true);
		let file = e.target.files[0];
		if (!file) {
			setLoadingProgress(false);
			return;
		}

		await uploadFile(file);
	};

	useEffect(() => {
		loadConfig();
	}, []);

	if (loadingProgress) {
		return (
			<MDBox
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				style={{ height: "100%" }}
			>
				<CircularProgress color="info" size={80} />
				<MDBox sx={{ mt: 4 }}>
					<MDTypography variant="h6">{i18n.t("SOURCES.UPLOAD.inProgress")}</MDTypography>
				</MDBox>
			</MDBox>
		);
	}

	return (
		<MDBox flex="1" display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
			<MDBox flex="5">
				<Grid container>
					{[
						{
							name: "Page Web",
							importMethod: "url",
							icon: "website"
						},
						{
							name: "Sitemap",
							importMethod: "sitemap",
							icon: "share"
						},
						...possibleIntegrations
					].map((channel, index) => {
						const importMethod =
							channel.importMethod ??
							AVAILABLES_CHANNELS.find(c => c.type === channel.type)?.importMethod;

						return (
							<Grid
								item
								xs={6}
								sm={4}
								md={3}
								lg={2}
								key={index}
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									alignItems: "center"
								}}
							>
								<MDBox
									className="integrationBox"
									display="flex"
									flexDirection="column"
									alignItems="center"
									justifyContent="space-between"
									borderRadius="lg"
									p={2}
									sx={{
										height: "100%",
										width: "100%"
									}}
									onClick={() => {
										selectMethod(importMethod);
									}}
								>
									{channel.icon && (
										<MDBox>
											<Icon fontSize="large">{channel.icon}</Icon>
										</MDBox>
									)}
									{channel.logoURL && (
										<MDBox>
											<MDBox component="img" src={channel.logoURL} width="35px" height="35px" />
										</MDBox>
									)}
									<MDTypography variant="body1" fontSize="small">
										{channel.name}
									</MDTypography>
								</MDBox>
							</Grid>
						);
					})}
				</Grid>
			</MDBox>
			<MDBox ml={1} flex="3" style={{ height: "100%", width: "100%" }}>
				<DragAndDrop />
			</MDBox>
		</MDBox>
	);

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			style={{ height: "100%", width: "100%" }}
		>
			<MDBox
				flex="1"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="strech"
				style={{ height: "100%", width: "50%", textAlign: "center" }}
			>
				{/* Title */}
				<MDBox mt={1}>
					<MDTypography variant="h4" component="h4">
						{i18n.t("SOURCES.UPLOAD.add")}
					</MDTypography>
				</MDBox>
				{/* Content */}
				<MDBox mt={5}>
					<MDInput
						type="file"
						className="dialogInput"
						onChange={e => {
							handleUploadFile(e);
						}}
					/>
				</MDBox>
				{/* Title */}
				<MDBox mt={1}>
					<MDTypography variant="h6" component="h4">
						{i18n.t("SOURCES.UPLOAD.or")}
					</MDTypography>
				</MDBox>
				{/* Title */}
				<MDBox
					mt={1}
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<MDInput
						disabled={loadUrl}
						value={url}
						className="dialogInput"
						placeholder={i18n.t("SOURCES.UPLOAD.urlPlaceholder")}
						onChange={e => {
							setUrlError("");
							setUrl(e.target.value);
						}}
						onKeyDown={e => {
							if (e.key === "Enter") {
								// downloadFileFromUrl();
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{!loadUrl && (
										<IconButton disabled={Boolean(!url)} color="info" onClick={() => {}}>
											<Icon>search</Icon>
										</IconButton>
									)}
									{loadUrl && <CircularProgress color="info" size={20} />}
								</InputAdornment>
							)
						}}
					/>
				</MDBox>
				{urlError && (
					<Fade in={Boolean(urlError)}>
						<MDTypography
							color="error"
							variant="button"
							style={{ marginTop: "0.5rem", textAlign: "center" }}
						>
							{urlError}
						</MDTypography>
					</Fade>
				)}
			</MDBox>
		</MDBox>
	);
}
