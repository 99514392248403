/* eslint-disable no-restricted-syntax */
import { IMPORT_METHODS } from "constants";
import SourceActions from "redux-react/actions/sourceActions";

/**
 * Check if sources exist in database
 * @param {[object]} sources - Array of sources
 * @returns {Promise} - Promise object represents the result of the check
 */
export const checkIfSourcesExist = async (dispatch, sources) => {
	return new Promise((resolve, reject) => {
		dispatch(
			SourceActions.checkIfSourcesExist(
				sources,
				res => {
					resolve(res);
				},
				err => {
					reject(err);
				}
			)
		);
	});
};

export const importSources = async (dispatch, method, sources) => {
	const files = [];

	for (let source of sources) {
		// { code, url } = source

		let file = {};

		switch (method) {
			case IMPORT_METHODS.LOCAL_FILE: {
				// Need to upload file to S3
				break;
			}
			default: {
				file.alreadyExists = source.alreadyExists;
				file.code = source.url;
				file.type = null;
				file.file = {
					name: source.name,
					contentType: null,
					url: source.url,
					provider: "external"
				};
				file.metadatas = {
					source: file.code
				};

				break;
			}
		}

		files.push(file);
	}

	return new Promise(resolve => {
		dispatch(
			SourceActions.importSources(
				{
					importMethod: method,
					sources: files
				},
				res => {
					resolve(res);
				},
				err => {
					resolve(err);
				}
			)
		);
	});
};
