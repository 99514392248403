/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { uploadToSignedUrl, getUploadSignedUrl, getDownloadSignedUrl } from "helpers/s3";
import FilesActions from "redux-react/actions/filesActions";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Fade, Grid, Icon, IconButton, InputAdornment } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "components/Advanced/Tables/DataTable";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";

export default function StepDisplay({ skeleton, validStep, handleNext, onChange }) {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const [selectedRows, setSelectedRows] = useState([]);

	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox flex="1" style={{ height: "100%", width: "100%", overflow: "auto" }}>
			<DefaultDataTable
				compressMode
				checkbox
				selectedRows={selectedRows}
				handleSelection={values => {
					setSelectedRows(values);
					onChange(values);
				}}
				isSelectedRow={row => {
					const existingRow = selectedRows.find(r => r.url === row.url);
					return Boolean(existingRow);
				}}
				//
				canSearch
				table={{
					rows: skeleton.files,
					columns: [
						{
							Header: "Nom",
							accessor: "name"
						},
						{
							Header: "Statut",
							accessor: "alreadyExists"
						},
						{
							Header: "Lien",
							accessor: "url"
						}
					]
				}}
				noHeader
				pagination={{
					defaultEntrie: 25,
					entriesPerPage: [15, 25, 50, 100, 200]
				}}
			/>
		</MDBox>
	);
}
