/* eslint-disable prefer-destructuring */
import { useRef } from "react";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { CloudUploadOutlined } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

/**
 *
 * @returns
 */
const DragAndDrop = () => {
	const theme = useTheme();
	const fileInputRef = useRef(null);

	const processFile = files => {
		console.log(files);
	};

	const handleDrop = event => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		// Handle the dropped files
		processFile(files);
	};

	const handleDragOver = event => {
		event.preventDefault();
	};

	const handleFileInputChange = event => {
		const files = event.target.files;
		// Handle the selected files
		processFile(files);
	};

	const handleClick = () => {
		fileInputRef.current.click();
	};

	return (
		<MDBox
			sx={{
				border: `2px dashed ${theme.palette.info.main}`,
				borderRadius: "8px",
				padding: "20px",
				textAlign: "center",
				color: theme.palette.info.dark,
				cursor: "pointer",
				height: "100%"
			}}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onClick={handleClick}
		>
			<input
				type="file"
				multiple
				ref={fileInputRef}
				style={{ display: "none" }}
				onChange={handleFileInputChange}
			/>
			<CloudUploadOutlined fontSize="large" sx={{ color: theme.palette.info.main }} />
			<MDTypography variant="h4" sx={{ marginTop: "10px" }}>
				Importer vos fichiers
			</MDTypography>
			<MDTypography variant="caption" sx={{ marginTop: "15px" }}>
				Cliquez ou glissez-déposez vos fichiers ici
			</MDTypography>
		</MDBox>
	);
};

export default DragAndDrop;
