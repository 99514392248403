/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import lod_ from "lodash";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import Step1Metadatas from "./processSteps/1. Metadatas";
import Step2Summary from "./processSteps/2. Summary";
import { socket } from "redux-react/middleware/ws";
import { display } from "redux-react/reducers/snackBarReducer";
import { useDispatch, useSelector } from "react-redux";
import Step3Extract from "./processSteps/3. Extract";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FaiqActions from "redux-react/actions/faiqActions";

export default function ProcessSourceDialog({
	open,
	handleCloseDialog,
	handleSave,
	source: sourceProps,
	metadatasDictionary
}) {
	const [source, setSource] = useState(sourceProps);
	const dispatch = useDispatch();
	const topContainer = useRef(null);

	const profile = useSelector(selectCurrentProfile);

	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 * Steps labels
	 */
	const [steps] = useState(["Metadonnées", "Résumé", "Connaissances"]);
	const isLastStep = activeStep === steps.length - 1;

	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [extractDialog, setExtractDialog] = useState(false);

	const [datas, setDatas] = useState({
		summaryGenerationDisabled: false,
		extractionDisabled: false,
		summary: source.summary || "",
		metadatas: {},
		fileName: lod_.get(source, "file.name")
	});

	// Close dialog
	function close() {
		handleCloseDialog();
	}
	// Save source
	async function submit() {
		dispatch(
			FaiqActions.updateSource(
				profile.assistantID,
				source.code,
				{
					metadatas: datas.metadatas,
					summary: datas.summary,
					temporary: {},
					"file.name": datas.fileName
				},
				() => {
					handleSave();
				}
			)
		);
		close();
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Go to back step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Extract knowledges
	 */
	const extractKnowledgesOpenDialog = () => {
		setExtractDialog(true);
	};

	const extractKnowledges = () => {
		const onSuccess = res => {
			dispatch(
				addNotification({
					assistantID: profile.assistantID,
					type: "info",
					message: "Les connaissances sont en cours d'extraction",
					ts: new Date().getTime()
				})
			);
			setDatas(skeleton => {
				return { ...skeleton, knowledgesExtractionInProgress: true };
			});
		};
		dispatch(FaiqActions.extractSource(profile.assistantID, source.code, onSuccess));
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<Step1Metadatas
						skeleton={source}
						handleSave={metadatas => {
							setDatas(skeleton => {
								return { ...skeleton, metadatas: metadatas };
							});
						}}
						handleSaveFileName={fileName => {
							setDatas(skeleton => {
								lod_.set(skeleton, "fileName", fileName);
								return { ...skeleton };
							});
						}}
						validStep={validStep}
						metadatas={metadatasDictionary}
					/>
				);
			case 1: {
				return (
					<Step2Summary
						validStep={validStep}
						skeleton={datas}
						onChange={summary => {
							setDatas(skeleton => {
								return { ...skeleton, summary: summary };
							});
						}}
						lockGenerateButton={() => {
							setDatas(skeleton => {
								return { ...skeleton, summaryGenerationDisabled: true };
							});
						}}
						source={source}
					/>
				);
			}
			case 2: {
				return (
					<Step3Extract
						validStep={validStep}
						source={source}
						skeleton={source}
						extractKnowledges={extractKnowledgesOpenDialog}
						setEditMode={setEditMode}
					/>
				);
			}
			default:
				return null;
		}
	}

	const sourceSummaryGeneratedResult = data => {
		setDatas(skeleton => {
			return { ...skeleton, summaryGenerationDisabled: false };
		});
		if (data.success) {
			setDatas(skeleton => {
				return { ...skeleton, summary: data.message };
			});
		} else {
			dispatch(
				display({
					message: "Une erreur est survenue lors de la génération du résumé",
					severity: "error"
				})
			);
		}
	};

	const sourceExtractedResult = data => {
		if (data.source) {
			setSource(data.source);
		}
		validStep();
		setDatas(skeleton => {
			return { ...skeleton, knowledgesExtractionInProgress: false };
		});
	};

	useEffect(() => {
		socket.on("source_extracted_result", sourceExtractedResult);
		socket.on("faiq_source_summary_generated_result", sourceSummaryGeneratedResult);

		return () => {
			socket.off("source_extracted_result", sourceExtractedResult);

			socket.off("faiq_source_summary_generated_result", sourceSummaryGeneratedResult);
		};
	}, []);

	return (
		<Dialog
			fullWidth
			open={open}
			onClose={() => close()}
			PaperProps={{
				sx: {
					height: "95%",
					width: "100%",
					maxWidth: "100%"
				}
			}}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>
				<MDBox mt={2} style={{ height: "90%" }}>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton disabled={editMode} variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				{activeStep > 0 && (
					<MDButton disabled={editMode} variant="contained" color="light" onClick={handleBack}>
						Retour
					</MDButton>
				)}
				{!source.extracted && isLastStep ? (
					<MDButton
						variant="contained"
						color="info"
						onClick={extractKnowledges}
						disabled={datas.knowledgesExtractionInProgress}
					>
						Extraire les connaissances&nbsp;
						{datas.knowledgesExtractionInProgress ? (
							<CircularProgress size={16} color="white" />
						) : (
							<Icon>auto_awesome</Icon>
						)}
					</MDButton>
				) : (
					<MDButton
						disabled={editMode || !stepValid}
						variant="contained"
						color="info"
						onClick={!isLastStep ? handleNext : submit}
					>
						{!isLastStep ? i18n.t("SETTINGS.next") : i18n.t("SETTINGS.save")}
					</MDButton>
				)}
			</DialogActions>

			<Dialog open={extractDialog} onClose={() => setExtractDialog(false)}>
				<DialogTitle>Extraction des connaissances</DialogTitle>
				<DialogContent>
					Attention, des connaissances sont déjà extraites pour cette source. Voulez-vous les
					extraire à nouveau ? Les connaissances actuelles seront écrasées.
				</DialogContent>
				<DialogActions>
					<MDButton
						variant="outlined"
						color="info"
						onClick={() => {
							setExtractDialog(false);
						}}
					>
						{i18n.t("SETTINGS.cancel")}
					</MDButton>
					<MDButton
						variant="contained"
						color="info"
						onClick={() => {
							extractKnowledges();
							setExtractDialog(false);
						}}
					>
						Valider
					</MDButton>
				</DialogActions>
			</Dialog>
		</Dialog>
	);
}
