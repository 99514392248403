/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import Step1Upload from "./addSourceSteps/1. Upload";
import Step2Datas from "./addSourceSteps/2. Datas";
import Step1Choice from "./addSourceSteps/2. Datas/steps/1. Choice";
import Step2View from "./addSourceSteps/2. Datas/steps/2. View";
import Step3Map from "./addSourceSteps/2. Datas/steps/3. Map";
import Step4Confirm from "./addSourceSteps/2. Datas/steps/4. Confirm";
import StepSelection from "./addSourceSteps/2. Selection";
import StepDisplay from "./addSourceSteps/3. Display";
import { importSources } from "hooks/sources";
import { useDispatch } from "react-redux";

export default function AddSourceDialog({
	open,
	handleCloseDialog,
	handleSave,
	metadatas,
	dictionary
}) {
	const dispatch = useDispatch();
	const topContainer = useRef(null);
	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);

	const moreStep = {
		file: [i18n.t("SOURCES.STEPS.dataChoice")],
		website: [i18n.t("SOURCES.STEPS.dataChoice")],
		csv: [
			"INSERTION",
			i18n.t("SOURCES.STEPS.visualize"),
			i18n.t("SOURCES.STEPS.associate"),
			i18n.t("SOURCES.STEPS.confirm")
		],
		BDD: []
	};
	/**
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SOURCES.STEPS.upload"),
		"Selection",
		i18n.t("SOURCES.STEPS.dataChoice")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);

	const [skeleton, setSkeleton] = useState({
		url: "",
		file: null,
		active: true,
		type: "file", // file | csv | BDD
		metadatas: {}
	});
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === [...steps /* , ...moreStep[skeleton.type] */].length - 1;

	const [filesToProcess, setFilesToProcess] = useState([]);

	/**
	 *
	 * @param {*} files
	 */
	const onChangeFilesToProcess = files => {
		setFilesToProcess(prev => files);
	};

	const [map, setMap] = useState({});

	// Close dialog
	function close(datas = null) {
		if (!datas) {
			datas = skeleton;
		}
		setActiveStep(0);
		setStepValid(false);
		setSkeleton({
			// url: "",
			// file: null,
			// active: true,
			// type: "file",
			// metadatas: {}
		});
		setMap({});
		handleCloseDialog(datas);
	}
	// Save knowledge
	async function sumbit() {
		console.log(skeleton);
		console.log(filesToProcess);

		let resultImport = await importSources(dispatch, skeleton.importMethod, filesToProcess);

		console.log("ok");

		//	handleSave({ payload: skeleton, map });
		// on save, submit no datas else it will remove file from s3
		close({});
	}
	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Go to back step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};

	/* const handleUploadFile = ({ type, url, file, values = [], metadatas = {} }) => {
		 setSkeleton(skeleton => {
			let newSkeleton = {
				...skeleton,
				type: type,
				url: url,
				// Values used for csv
				values: values,
				// File used for file
				file: {
					name: file.name,
					size: file.size,
					type: file.type,
					path: file.path
				},
				metadatas: {
					...skeleton.metadatas,
					...metadatas
				}
			};
			return newSkeleton;
		}); 

		handleNext();
	}; */

	const handleUploadFile = ({ importMethod, files = [] }) => {
		// setTemporaryFiles(prev => files);

		setSkeleton(skeleton => {
			return {
				importMethod,
				files
			};
		});

		handleNext();
	};

	const handleEditMetadatas = metadatas => {
		setSkeleton(skeleton => {
			return { ...skeleton, metadatas: metadatas };
		});
	};

	const selectMethod = method => {
		switch (method) {
			case "unstructured":
				setSkeleton(skeleton => {
					return {
						...skeleton,
						type: "file",
						values: []
					};
				});
				break;
			default:
				handleNext();
		}
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<Step1Upload
						handleSave={handleUploadFile}
						handleNext={handleNext}
						validStep={validStep}
					/>
				);
			case 1: {
				return (
					<StepSelection
						skeleton={skeleton}
						handleSave={files => {
							setSkeleton(skeleton => {
								return {
									...skeleton,
									files
								};
							});
							validStep();
							handleNext();
						}}
						handleNext={handleNext}
						validStep={validStep}
					/>
				);
			}
			case 2: {
				return (
					<StepDisplay
						skeleton={skeleton}
						onChange={onChangeFilesToProcess}
						validStep={validStep}
					/>
				);
				// switch (skeleton.type) {
				// 	case "csv":
				// 		return <Step1Choice selectMethod={selectMethod} />;
				// 	default:
				// 		return (
				// 			<Step2Datas
				// 				skeleton={skeleton}
				// 				handleSave={handleEditMetadatas}
				// 				validStep={validStep}
				// 				metadatas={metadatas}
				// 			/>
				// 		);
				// }
			}
			case 3: {
				switch (skeleton.type) {
					case "csv":
						return <Step2View validStep={validStep} datas={skeleton.values} />;
					default:
						return null;
				}
			}
			case 4: {
				switch (skeleton.type) {
					case "csv":
						return (
							<Step3Map
								validStep={validStep}
								values={skeleton.values}
								dictionary={{
									items: {
										knowledge: dictionary.faiqKnowledge,
										metadatas: dictionary.metadatas
									}
								}}
								map={map}
								setMap={setMap}
							/>
						);
					default:
						return null;
				}
			}
			case 5: {
				switch (skeleton.type) {
					case "csv":
						return <Step4Confirm validStep={validStep} skeleton={skeleton} />;
					default:
						return null;
				}
			}
			default:
				return null;
		}
	}

	return (
		<Dialog
			fullWidth
			maxWidth="xxl"
			PaperProps={{
				sx: {
					height: "90%"
				}
			}}
			open={open}
			onClose={close}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{[...steps /* , ...moreStep[skeleton.type] */].map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>
				<MDBox mt={2} style={{ height: "90%" }}>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				{activeStep > 0 && (
					<MDButton
						/* disabled={!stepValid} */ variant="contained"
						color="light"
						onClick={handleBack}
					>
						Retour
					</MDButton>
				)}
				{activeStep > 0 && (
					<MDButton
						disabled={!stepValid || (isLastStep ? filesToProcess.length < 1 : false)}
						variant="contained"
						color="info"
						onClick={!isLastStep ? handleNext : sumbit}
					>
						{!isLastStep
							? i18n.t("SETTINGS.next")
							: /* i18n.t("SETTINGS.save") */ `Importer (${filesToProcess.length})`}
					</MDButton>
				)}
			</DialogActions>
		</Dialog>
	);
}
