import axios from "axios";
import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	deleteSource: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/source/${assistantID}/${code}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression de la source"
							})
						);
					}
				})
			);
		};
	},
	deleteSources: (assistantID, codes, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/source/many/${assistantID}`,
					data: {
						sources: codes
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression des sources"
							})
						);
					}
				})
			);
		};
	},
	updateSource: (assistantID, code, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/source/${assistantID}/${code}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour de la source"
							})
						);
					}
				})
			);
		};
	},
	/* Update the metadatas for X sources & sources knowledges */
	updateSourcesMetadatas: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/source/metadatas/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour des métadonnées"
							})
						);
					}
				})
			);
		};
	},
	extractSource: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/extract/${assistantID}/${code}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'extraction de la source"
							})
						);
					}
				})
			);
		};
	},
	/* Start source process for X sources */
	processSources: (assistantID, sources, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/process/${assistantID}`,
					data: {
						sources
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Get the channels integration */
	getIntegrationChannels: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/channels`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Get sitemap */
	getIntegrationSitemap: (url, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/sitemap`,
					data: {
						url
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						onFailure(err);
					}
				})
			);
		};
	},
	/* Check if sources exist in database */
	checkIfSourcesExist: (sources, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/exists`,
					data: {
						sources
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						onFailure(err);
					}
				})
			);
		};
	},
	/* Save sources */
	importSources: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/import`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'insertion de la source"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
