import { Alert, CircularProgress, Fade, Icon, IconButton, InputAdornment } from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useState, useEffect } from "react";
import FilesActions from "redux-react/actions/filesActions";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import { useSelector, useDispatch } from "react-redux";
import { CheckCircleOutline } from "@mui/icons-material";
import { checkIfSourcesExist } from "hooks/sources";

const SiteMapImport = ({ onFilesSelection, validStep }) => {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const [loadingProgress, setLoadingProgress] = useState(false);

	const [url, setUrl] = useState("");
	const [urlError, setUrlError] = useState(null);
	const [loadUrl, setLoadUrl] = useState(false);

	const downloadFileFromUrl = async () => {
		setLoadingProgress(true);

		dispatch(
			SourceActions.getIntegrationSitemap(
				url,
				async res => {
					const filesResult = await checkIfSourcesExist(dispatch, res);
					onFilesSelection(filesResult);
					validStep();
					setLoadingProgress(false);
				},
				err => {
					setLoadingProgress(false);
					setUrlError(t("SOURCES.UPLOAD.urlError"));
				}
			)
		);
	};

	return (
		<MDBox mt={5} display="flex" flex="1" flexDirection="row" justifyContent="center">
			<MDBox sx={{ width: "70%" }}>
				<MDTypography variant="h4">Insérer un lien vers un sitemap</MDTypography>
				<MDInput
					autoFocus
					sx={{ mt: 5 }}
					disabled={loadUrl}
					value={url}
					className="dialogInput"
					placeholder={t("SOURCES.UPLOAD.urlPlaceholder")}
					onChange={e => {
						setUrlError("");
						setUrl(e.target.value);
					}}
					onKeyDown={e => {
						if (e.key === "Enter") {
							downloadFileFromUrl();
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{!loadUrl && (
									<IconButton disabled={Boolean(!url)} color="info" onClick={downloadFileFromUrl}>
										<Icon>search</Icon>
									</IconButton>
								)}
								{loadUrl && <CircularProgress color="info" size={20} />}
							</InputAdornment>
						)
					}}
				/>
				{/* Loading */}
				{loadingProgress && (
					<Fade in={loadingProgress} timeout={300}>
						<MDBox sx={{ mt: 3 }}>
							<Alert
								icon={
									<CircularProgress size={25} sx={{ marginRight: 2 }} thickness={5} color="info" />
								}
								severity="info"
							>
								<MDTypography variant="h6" fontSize="small" color="info">
									récupération du sitemap en cours ...
								</MDTypography>
							</Alert>
						</MDBox>
					</Fade>
				)}
				{/* Error */}
				{urlError && (
					<Fade in={!lod_.isNil(urlError) && !lod_.isEmpty(urlError)} timeout={300}>
						<MDBox sx={{ mt: 3 }}>
							<Alert icon={<Icon color="error">close</Icon>} severity="error">
								<MDTypography variant="h6" fontSize="small" color="error">
									{urlError}
								</MDTypography>
							</Alert>
						</MDBox>
					</Fade>
				)}
			</MDBox>
		</MDBox>
	);
};

export default SiteMapImport;
