/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { uploadToSignedUrl, getUploadSignedUrl, getDownloadSignedUrl } from "helpers/s3";
import FilesActions from "redux-react/actions/filesActions";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Fade, Grid, Icon, IconButton, InputAdornment } from "@mui/material";
import { read, utils } from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import DragAndDrop from "pages/sources/components/DragAndDrop";
import SiteMapImport from "./components/SiteMapImport";
import { checkIfSourcesExist } from "hooks/sources";
import { IMPORT_METHODS } from "constants";

export default function StepSelection({ skeleton, validStep, handleNext, handleSave }) {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	if (lod_.isNil(skeleton.importMethod)) {
		return <MDBox>:(</MDBox>;
	}

	const onFilesSelection = async files => {
		handleSave(files);
	};

	const getSelectionMethod = importMethod => {
		switch (importMethod) {
			case IMPORT_METHODS.URL: {
				return <div>ok</div>;
			}
			case IMPORT_METHODS.SITEMAP: {
				return (
					<SiteMapImport
						onFilesSelection={onFilesSelection}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			}
			default:
				return <MDBox>:(</MDBox>;
		}
	};

	return (
		<MDBox flex="1" display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
			{getSelectionMethod(skeleton.importMethod)}
		</MDBox>
	);
}
